// eslint-disable-next-line import/prefer-default-export
export const onlineOrdHero = {
  title:
    "Your commission-free solution for online ordering, delivery, and more",
  subtext:
    "Save money, simplify operations, and own your guest data, with the all-in-one mobile & online ordering platform.",
  heroImg: "oo-lp.png",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/online-ordering-a/demo",
  },
  fatTitle: "",
};

export const onlineOrdTrusted = [
  {
    title: "70%",
    subtext: "Guests prefer to order directly from a restaurant",
  },
  {
    title: "67%",
    subtext: "Online ordering customers visit more often",
  },
  {
    title: "18%",
    subtext: "Increase in average ticket with online ordering",
  },
  {
    title: "$7,500",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
];

export const onlineOrdWhiteGlove = {
  mainTitle: "The support you need, when you need it",
  title: "Get hands-on setup and support every step of the way",
  content:
    "Are you tired of losing money to third-party delivery apps? Research shows businesses are giving away up to $250 a day in commissions. Stop giving money away and start saving with an online ordering system that works to your benefit, not theirs. Our dedicated team of specialists will work with you to create and configure an online menu that sends orders directly to your point-of sale, while creating a seamless pickup and delivery experience for your guests.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/online-ordering-a/demo",
  },
};

export const onlineOrdLargeFeatures = {
  sectionTitle: "Seamless online ordering technology your guests will love",
  featureBlocks: [
    {
      blockTitle:
        "Goodbye third-party delivery services. Hello easy POS ordering system",
      blockSubTitle:
        "Our easy-to-use online ordering system makes it easy for your guests to order directly from your website, Facebook page, and Google—so you can stop paying third-party commissions and start saving time and money. With direct POS integration and pacing controls for online orders, you can easily boost sales and kitchen efficiency. ",
      blockImg: "oo-lp-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/online-ordering-a/demo",
      },
    },
    {
      blockTitle: "Compete & win with hassle-free local delivery",
      blockSubTitle:
        "Keep your cut of delivery profits without hiring and managing your own drivers. With SpotOn Delivery, you can leverage the DoorDash delivery network at a fraction of the cost while still owning your guest data.",
      blockImg: "oo-lp-b.png.png",
      blockList: [
        "Customers order directly from your website",
        "Pay a flat fee as low as $4 per order",
        "DoorDash handles delivery logistics—no account required",
        "Increase loyalty and retention by retargeting customers with built-in marketing tools",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/online-ordering-a/demo",
      },
    },
    {
      blockTitle: "Streamline the payment experience with contactless options",
      blockSubTitle:
        "Whether your guests are ordering for delivery, curbside pickup, or dining in, uphold safety regulations with contactless payment options. We make it easy to provide secure checkout options that keep your staff and guests safe, including: ",
      blockList: [
        "QR codes",
        "Egift cards",
        "Tap-to-pay credit cards ",
        "Mobile wallets like Apple Pay & Google Pay ",
      ],
      blockImg: "oo-lp-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/online-ordering-a/demo",
      },
    },
  ],
};

export const onlineOrdTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const onlineOrdCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/online-ordering-a/demo",
  },
};
